/*
 * -------------------------------------------------------------------------------
 *
 * Copyright 2022 Valory AG
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * -------------------------------------------------------------------------------
 */

import styled from 'styled-components';
import { Layout } from 'antd/lib';
import { COLOR, MEDIA_QUERY } from 'common-util/theme';

export const CustomLayout = styled(Layout)`
  .header-container {
    svg {
      margin-right: 1rem;
    }
    h1.ant-typography {
      margin: 0;
      max-width: 180px;
      font-size: 34px;
      flex: 1 1 0%;
      color: ${COLOR.PRIMARY};
      font-family: manrope__extrabold, sans-serif;
    }
    .ant-btn {
      border-radius: 30px;
      text-shadow: none;
      font-family: manrope__bold, sans-serif;
    }
    .sub-header {
      &-title {
        font-size: 32px;
        font-weight: bold;
      }
      &-price {
        font-weight: bold;
      }
    }

    ${MEDIA_QUERY.tablet} {
      h1.ant-typography {
        line-height: normal;
        font-size: 42px;
        font-family: manrope__medium, sans-serif;
        margin-bottom: 1rem;
      }
      .sub-header {
        &-title {
          font-size: 46px;
        }
      }
    }
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0 10rem 0;
  position: relative;
  .socials {
    display: flex;
    column-gap: 28px;
  }
`;

export const BodyContainer = styled.div`
  min-height: calc(100vh - 180px);
`;
