/*
 * -------------------------------------------------------------------------------
 *
 * Copyright 2022 Valory AG
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * -------------------------------------------------------------------------------
 */

import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { ServiceStatusInfo } from '@autonolas/frontend-library';
import {
  Typography, Button, Menu, Layout,
} from 'antd/lib';
import { useCheckMobileScreen } from 'common-util/hooks';
import Footer from './Footer';
import { CustomLayout } from './styles';

const { Header: HeaderContainer, Content } = Layout;
const LogoSvg = dynamic(() => import('common-util/SVGs/logo'));

const { Title } = Typography;

const Header = ({ children }) => {
  const isMobile = useCheckMobileScreen();
  const router = useRouter();
  const [selectedMenu, setSelectedMenu] = useState([]);
  const { pathname } = router;
  const title = <Title>Oracles</Title>;

  // to set default menu on first render
  useEffect(() => {
    if (pathname) {
      const name = pathname.split('/')[1];
      setSelectedMenu(name || null);
    }
  }, [pathname]);

  const handleMenuItemClick = ({ key }) => {
    router.push(`/${key}`);
    setSelectedMenu(key);
  };

  return (
    <CustomLayout>
      <HeaderContainer className="header-container">
        <LogoSvg />
        {!isMobile && title}

        <Menu theme="light" mode="horizontal" selectedKeys={[selectedMenu]}>
          <Menu.Item key="price" onClick={handleMenuItemClick}>
            Price Oracle
          </Menu.Item>
          <Menu.Item key="ml-apy-prediction" onClick={handleMenuItemClick}>
            ML APY Prediction Oracle
          </Menu.Item>
        </Menu>

        <Button
          type="primary"
          size="large"
          onClick={() => window.open('https://www.autonolas.network')}
        >
          {`LEARN MORE${isMobile ? '' : ' ABOUT AUTONOLAS'}`}
        </Button>
      </HeaderContainer>

      <ServiceStatusInfo
        appType={selectedMenu === 'ml-apy-prediction' ? 'mlkit' : 'oraclekit'}
      />

      <Content className="site-layout">
        <div className="site-layout-background">{children}</div>
        <Footer />
      </Content>
    </CustomLayout>
  );
};

Header.propTypes = {
  children: PropTypes.element,
};

Header.defaultProps = {
  children: null,
};

export default Header;
